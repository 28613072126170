import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
    return (
        <div>
            <div className="header">
                <div className="nav-brand">
                    <div className="logo">
                        <div className="logo-content">
                            <div className='logo-div'>
                                <img src="../images/appa.jpg" alt='logo' height={40} />
                            </div>
                            <div className="logo-text-div">
                                <div className='logo-title'>APPA</div>
                                <div className='logo-text'>Automation</div>
                            </div>
                        </div>
                    </div>
                </div>
                <i className="fa fa-bars fa-3x"></i>
                <div className="header-links">
                    <ul>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="#aboutus">About Us</a></li>
                        <li><a href="#">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className="banner">
                <img src="../images/soft.png" className="m-5" />
            </div>

            <div id="aboutus" className="divid">
                <img src="../images/baners.png" className="banerss" />
            </div>

            <div className="container mb-4 mt-2">
                <span className="badge bg-light text-dark mb-20 animated" data-show="startbox" >Tag line</span>
                <div className="row gy-45">
                    <div className="col-lg-5">
                        <div className="pe-lg-70">
                            <h3 className="m-0 animated" data-show="startbox" data-show-delay="100" classNameName="transform: translateY(0px); transition-duration: 500ms; opacity: 1;">You <span className="highlight">share your idea</span>, <br></br>we get it done.</h3>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <p data-show="startbox" data-show-delay="200" className="animated" classNameName="transform: translateY(0px); transition-duration: 500ms; opacity: 1;">In the modern world, the journey from idea to execution can often seem daunting. However, with the right partners, this journey can become a seamless and exciting process. "You share your idea. We get it done" embodies the essence of collaboration and innovation. When you bring your concept to us, you are not just presenting a thought; you are laying the foundation for a transformative project..</p>
                        <p className="mb-0 animated" data-show="startbox" data-show-delay="300" classNameName="transform: translateY(0px); transition-duration: 500ms; opacity: 1;">Our dedicated team of experts listens attentively, understanding every nuance of your vision. We then leverage our diverse skill sets, cutting-edge technology, and industry experience to bring your idea to life. Whether it's developing a new product, launching a marketing campaign, or implementing a business solution, we take pride in transforming your dreams into tangible realities, ensuring that every step of the process aligns with your goals and exceeds your expectations.</p>
                    </div>
                </div>
            </div>
            <div className="divid">
                <img src="../images/baners.png" className="banerss" />
            </div>
            <div className="container mt-4 mb-4">
                <div className="row d-flex">
                    <div className="col-12 col-md-9 col-lg-3 col-hg-10 custght" >
                        <div className="service-box lift position-relative rounded-9 bg-light text-center service-box-sm pt-3">
                            <div className="circle-icon text-white bg-accent-2 mb-60">
                                <img src="../images/user.png" width="80" height="80" fill="none" />
                            </div>
                            <h4 className="service-box-title mb-15">Personalization</h4>
                            <p className="service-box-text font-size-15 mb-0">tailors experiences and products to individual preferences, enhancing engagement and satisfaction.</p><a className="stretched-link" href="service-single.html"></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-3 col-hg-10 custght " >
                        <div className="service-box lift position-relative rounded-4 bg-light text-center service-box-sm pt-3">
                            <div className="circle-icon text-white bg-accent-2 mb-30">
                                <img src="../images/love.png" width="80" height="80" fill="none" />
                            </div>
                            <h4 className="service-box-title mb-15">Customer Support</h4>
                            <p className="service-box-text font-size-15 mb-0">ensures timely and effective assistance to resolve issues and enhance customer satisfaction.</p><a className="stretched-link" href="service-single.html"></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-3 col-hg-10 custght " >
                        <div className="service-box lift position-relative rounded-4 bg-light text-center service-box-sm pt-3">
                            <div className="circle-icon text-white bg-accent-2 mb-30">
                                <img src="../images/privacy.png" width="80" height="80" fill="none" />
                            </div>
                            <h4 className="service-box-title mb-15">Privacy</h4>
                            <p className="service-box-text font-size-15 mb-0">protection of personal information and ensuring it remains secure and confidential.</p><a className="stretched-link" href="service-single.html"></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-3 col-hg-10 custght " >
                        <div className="service-box lift position-relative rounded-4 bg-light text-center service-box-sm pt-3">
                            <div className="circle-icon text-white bg-accent-2 mb-30">
                                <img src="../images/book.png" width="80" height="80" fill="none" />
                            </div>
                            <h4 className="service-box-title mb-15">Case Studies</h4>
                            <p className="service-box-text font-size-15 mb-0">provides detailed insights and real-world examples of how solutions have been effectively implemented to address specific challenges.</p><a className="stretched-link" href="service-single.html"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divid">
                <img src="../images/baners.png" className="banerss" />
            </div>

            <div className="col-lg-6 offset-lg-3 text-center px-lg-50">
                <h2 className="mb-36 px-lg-59  startbo" >Latest Mobile APP <span className="proje">Projects</span></h2>
                <p className="title">Our latest mobile app projects showcase cutting-edge technology and innovative designs tailored to enhance user experiences. These projects focus on delivering seamless functionality and intuitive interfaces, meeting the diverse needs of our clients.</p>
            </div>
            <div className="divid">
                <img src="../images/baners.png" className="banerss" />
            </div>

            <section class="section-1">
                <div className="sect">
                    <figure className="figure">
                        <img src="../images/mobil01.jpeg" />
                        <figcaption>
                            <h3>Buy Now</h3>
                        </figcaption>
                        <a href="#"></a>
                    </figure>
                    <figure className="figure">
                        <img src="../images/mobile02.jpeg" />
                        <figcaption>
                            <h3>Read More</h3>
                        </figcaption>
                        <a href="#"></a>
                    </figure>
                    <figure className="figure">
                        <img src="../images/mobile03.jpeg" />
                        <figcaption>
                            <h3>Join Us</h3>
                        </figcaption>
                        <a href="#"></a>
                    </figure>
                </div>
            </section>
            <div id="contact" >
                <footer className="bg-dark text-white pt-120 pb-100 footerNext padding-top: 371.75px !important;">
                    <div className="container">
                        <div className="row gy-50">
                            <div className="col-12 col-lg-4">
                                <img src="../images/appa.jpg" width="60" fill="none" className=" appu" />
                                <div className="aparna">APPA Infotech Services Pvt. Ltd.</div>
                                <div className="2024">Banashankari 1st Stage</div>
                                <div className="2024">BENGALURU - 50</div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="row gy-50 p-5">
                                    <div className="col-6 col-md-4">
                                        <h6 className="aparna text-white ms-3 mb-20">Services</h6>
                                        <ul className="nav flex-column text-white nav-opacity nav-gap-sm">
                                            <li className="nav-item"><a className="nav-link" href="#">Consulting</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Human Resources</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Accounting</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Marketing &amp; SEO</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <h6 className="aparna text-white ms-3 mb-20">About</h6>
                                        <ul class="nav flex-column text-white nav-opacity nav-gap-sm">
                                            <li className="nav-item"><a className="nav-link" href="#aboutus">About us</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Our Services</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Our Blog</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Contact us</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <h6 className="aparna text-white ms-3 mb-20">Contact</h6>
                                        <ul className="nav flex-column text-white nav-opacity nav-gap-sm">
                                            <li className="nav-item"><a className="nav-link" href="tel:12023580309">8861012023</a></li>
                                            <li className="nav-item"><a className="nav-link" href="mailto:hello@startbox.com">sghagk@gmail.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Home;