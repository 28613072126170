import './App.css';
import Home from './components/Home';

import 'bootstrap/dist/css/bootstrap.min.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
    <Home />
    </div>
  );
}

export default App;
